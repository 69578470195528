import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AUTH_INTERCEPTOR_DENYLIST } from '../../auth.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const authService = this.injector.get(NbAuthService);

    if (AUTH_INTERCEPTOR_DENYLIST.some((endpoint) => req.url.includes(endpoint))) {
      return next.handle(req);
    }

    return authService.isAuthenticatedOrRefresh().pipe(
      switchMap(authenticated => {
        if (authenticated) {
          return authService.getToken().pipe(
            switchMap((token: NbAuthToken) => {
              const headers: HttpHeaders = req.headers
              .set('Authorization', `Bearer ${token.getValue()}`);
              return next.handle(req.clone({headers}));
            }),
          );
        } else {
          return next.handle(req);
        }
      })
    );
  }

}
