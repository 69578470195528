import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '@hearing-env/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  NbThemeModule, NbSidebarModule, NbMenuModule, NbToastrModule, NbWindowModule, NbDialogModule, NbDatepickerModule
} from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import es from 'date-fns/locale/es';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { NbAuthModule, NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService } from '@nebular/auth';
import { AUTH_CONFIG, DenyListFilter, CustomNbAuthService } from './auth.config';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';

import { ProfileState } from '@hearing-core/stores/profile/profile.state';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { HttpErrorInterceptor } from '@hearing-core/services/interceptors/http-error-interceptor.service';
import { CoreModule } from '@hearing-core/core.module';
import { LoadingInterceptor } from '@hearing-core/services/interceptors/loading.service';
import { StripeInterceptor } from './auth/interceptors/stripe.interceptor';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { RouteHandler } from '@hearing-core/services/handlers/route-handler.service';
import { NgxStripeModule } from 'ngx-stripe';

registerLocaleData(localeEs);

const NGXS_MODULES = [
  NgxsModule.forRoot([
    ProfileState
  ], {
    developmentMode: !environment.production
  }),
  NgxsSelectSnapshotModule.forRoot(),
  NgxsReduxDevtoolsPluginModule.forRoot({
    disabled: environment.production
  })
];

const NB_MODULES = [
  NbThemeModule.forRoot({ name: 'hearing' }),
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbToastrModule.forRoot(),
  NbAuthModule.forRoot(AUTH_CONFIG),
  NbWindowModule.forRoot(),
  NbDialogModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbDateFnsDateModule.forRoot({
    format: 'dd/MM/yyyy',
    parseOptions: { locale: es },
    formatOptions: { locale: es }
  }),
  NbEvaIconsModule,
];

const THIRD_PARTY_MODULES = [
  AgmCoreModule.forRoot({
    apiKey: environment.googleMapsApiKey,
    libraries: ['places']
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (http: HttpClient) => (new TranslateHttpLoader(http, 'assets/i18n/', '.json')),
      deps: [HttpClient]
    }
  }),
  NgxStripeModule.forRoot(environment.stripeApiKey),
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NB_MODULES,
    NGXS_MODULES,
    THIRD_PARTY_MODULES,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: (loader: MapsAPILoader) => (() => loader.load()), multi: true, deps: [MapsAPILoader] },
    { provide: APP_INITIALIZER, useFactory: (handler: RouteHandler) => (() => handler.handle()), multi: true, deps: [RouteHandler] },
    { provide: NbAuthService, useClass: CustomNbAuthService},
    { provide: HTTP_INTERCEPTORS, useClass: StripeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [Injector] },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: DenyListFilter },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
