import { IsNotEmpty, IsEmail } from 'class-validator';
import { Serializable } from '../serializable/serializable';

export class Profile extends Serializable {

  username: string;

  @IsNotEmpty()
  firstName: string;

  @IsNotEmpty()
  lastName: string;

  @IsEmail()
  email: string;

  centersCount: number;

  mainCenterSlug?: string;

  constructor(data?: any) {
    super(data);
  }
}
