import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NbSidebarService, NbMediaBreakpointsService, NbMediaBreakpoint } from '@nebular/theme';

@Component({
  selector: 'hearing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    private router: Router,
    private sidebarService: NbSidebarService,
    private mediaBreakpointService: NbMediaBreakpointsService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('es');
    this.onRouteChange();
  }

  private onRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      const widthBreakpoint: NbMediaBreakpoint = this.mediaBreakpointService.getByWidth(window.innerWidth);
      if (widthBreakpoint.name.match(/^(xs|is|sm)$/)) {
        this.sidebarService.collapse();
      }
    });
  }
}
