import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private loading = false;
  loadingStatus: Subject<any> = new Subject();

  get isLoading(): boolean {
    return this.loading;
  }

  set isLoading(value) {
    this.loading = value;
    this.loadingStatus.next(value);
  }

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }
}
