import { Profile } from '@hearing-core/models/profile/profile';

export class RetrieveProfile {
  static readonly type = '[Profile] Retrive user profile';
  constructor() {}
}

export class EditProfile {
  static readonly type = '[Profile] Edit user profile';
  constructor(public newProfile: Profile) {}
}
