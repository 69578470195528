import { BASE_ENV } from './base';

const DOMAIN_URL = 'https://www.audifono.es';

export const environment = {
  ...BASE_ENV,
  production: true,
  centersApiUrl: `${DOMAIN_URL}/centers_api/v1/`,
  domainUrl: `${DOMAIN_URL}`,
  staticUrl: ``,
  googleMapsApiKey: 'AIzaSyBPx_I7vo4uxJjdrDQoFeJX_CYIqL0KnJk',
  stripeApiKey: 'pk_live_HDPRVyYoG8wRfEoF3UTtP4st00Vumk6wZc'
};
