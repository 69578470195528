import { plainToClassFromExist } from 'class-transformer';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

export class Serializable {

  constructor(data?: any) {
    plainToClassFromExist(this, data ? camelCaseKeys(data, { deep: true }) : data);
  }

  static toDTO(data: any): any {
    return snakeCaseKeys(data, { deep: true }) as any;
  }
}
