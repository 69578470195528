import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteHandler {

  private routeChanges: Subscription;

  private routesTriggersSidebarCollapse = [
    '/dashboard/center/',
  ];

  constructor(
    private router: Router,
    private sidebar: NbSidebarService,
  ) {}

  public handle() {
    this.routeChanges?.unsubscribe();
    this.routeChanges = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe(
      (event: NavigationStart) => {
        this.handleSidebar(event.url);
      }
    );
  }

  private handleSidebar(url: string) {
    let match = false;
    for (const route of this.routesTriggersSidebarCollapse) {
      if (url.includes(route)) {
        match = true;
        this.sidebar.collapse('left');
        break;
      }
    }
    if (!match) {
      this.sidebar.expand('left');
    }
  }
}
