import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private ngZone: NgZone
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        const handled = this.handleErrorForStatusCode(err);
        if (handled) {
          return of(new HttpResponse(req));
        } else {
          return throwError(err);
        }
      })
    );
  }

  private handleErrorForStatusCode(err: HttpErrorResponse): boolean {

    switch (err.status) {
      case 401:
        this.ngZone.run(() => this.router.navigate(['/auth/login']));
        return true;
      case 404:
        this.ngZone.run(() => this.router.navigate(['/dashboard/404-not-found']));
        return true;
      default:
        return false;
    }
  }

}
