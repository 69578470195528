import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile } from '@hearing-core/models/profile/profile';
import { environment } from '@hearing-env/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public profileUrl = `${environment.centersApiUrl}profile/`;

  constructor(
    private http: HttpClient
  ) { }

  public getProfile(): Observable<Profile> {
    return this.http.get<Profile>(`${this.profileUrl}`);
  }

  public editProfile(newProfile: Profile): Observable<Profile> {
    return this.http.patch<Profile>(`${this.profileUrl}edit/`, newProfile);
  }

  public changePassword(password: object): Observable<Profile> {
    return this.http.post<Profile>(`${this.profileUrl}change_password/`, password);
  }
}
