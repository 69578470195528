import { State, Action, StateContext, Selector } from '@ngxs/store';
import { RetrieveProfile, EditProfile } from './profile.actions';
import { Profile } from '@hearing-core/models/profile/profile';
import { ProfileService } from '@hearing-core/services/profile.service';
import { tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class ProfileStateModel {
  public profile: Profile;
  public loading: boolean;
}

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    profile: {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      centersCount: 0,
      mainCenterSlug: null
    },
    loading: true
  }
})
@Injectable()
export class ProfileState {

  constructor(
    private profileService: ProfileService
  ) { }

  @Selector()
  static profile(state: ProfileStateModel) {
    return state.profile;
  }

  @Selector()
  static loading(state: ProfileStateModel) {
    return state.loading;
  }

  @Action(RetrieveProfile)
  retrieve(ctx: StateContext<ProfileStateModel>, action: RetrieveProfile) {
    return this.profileService.getProfile().pipe(
      map(profile => new Profile(profile)),
      tap(profile => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          profile,
          loading: false
        });
      })
    );
  }

  @Action(EditProfile)
  edit(ctx: StateContext<ProfileStateModel>, action: EditProfile) {
    return this.profileService.editProfile(Profile.toDTO(action.newProfile)).pipe(
      map(profile => new Profile(profile)),
      tap(profile => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          profile
        });
      })
    );
  }

}
